import store from 'appRedux/store';
import server from 'services/server';
import { API_URI } from 'constants/index';

export const TABLE_PER_PAGE = 100;

const _ = {
  name: 'orderList',
  state: {
    items: [],
    itemsPdf: [],
    infor: {},
    conditions: {},
    pagination: {},
    location: {},
    group_by: null,
    status: false,
    list: [],
    listComplete: [],
    listReceipt: [],
    list7Days: [],
    listLockUser: [],
    listUpdatePosition: [],
    listOrderCheck: [],
    statusGetCardTrello: false,
    loadingRobot: false
  }, // initial state
  reducers: {
    setData(state, payload) {
      return {
        ...state,
        ...payload,
      };
    },
    setDataComplete(state, payload) {
      return {
        ...state,
        listComplete: payload,
      };
    },
    setLocation(state, payload) {
      return {
        ...state,
        location: payload
      };
    },
    updateStatus(state, payload) {
      return {
        ...state,
        status: payload
      };
    },
    setCondition(state, payload) {
      return {
        ...state,
        conditions: payload
      };
    },
    resetCondition(state) {
      return {
        ...state,
        conditions: {}
      };
    },
    setDataInfor(state, payload) {
      return {
        ...state,
        ...payload
      };
    },
    setDataReceipt(state, payload) {
      return {
        ...state,
        listReceipt: payload
      };
    },
    setStatusGetCardTrello(state, payload) {
      return {
        ...state,
        statusGetCardTrello: payload
      };
    },
    setDataLockUser(state, payload) {
      return {
        ...state,
        listLockUser: payload
      };
    },
    setDataUpdatePosition(state, payload) {
      return {
        ...state,
        listUpdatePosition: payload
      };
    },
    setDataCheck(state, payload) {
      return {
        ...state,
        listOrderCheck: payload
      };
    },
    setLoadingRobot(state, payload) {
      return {
        ...state,
        loadingRobot: payload
      };
    }
  },
  effects: {
    async getList(queries) {
      const { data } = await server.get(`${API_URI}/orders`, {
        params: {
          ...queries,
          limit: TABLE_PER_PAGE
        },
      });
      this.setData(data);
      this.setCondition(queries);
    },
    async getListComplete(queries) {
      const { data } = await server.get(`${API_URI}/orders/listComplete`, {
        params: {
          ...queries,
          limit: TABLE_PER_PAGE
        },
      });
      this.setDataComplete(data);
      this.setCondition(queries);
    },
    async approveOrder(params) {
      try {
        const { data } = await server.post(`${API_URI}/orders/approveOrder`, params);
        this.updateStatus(true);
        return data;
      } catch({response : { data: { message }, data}}) {
        if(typeof(message) !== 'undefined') {
          return [message];
        } else {
          return [JSON.stringify(data)];
        }
      }
    },
    async signOrder(params) {
      try {
        const { data } = await server.post(`${API_URI}/orders/signOrder`, params);
        this.updateStatus(true);
        return data;
      } catch({response : { data: { message }, data}}) {
        if(typeof(message) !== 'undefined') {
          return [message];
        } else {
          return [JSON.stringify(data)];
        }
      }
    },
    async acceptDebt(params) {
      try {
        const { data } = await server.post(`${API_URI}/orders/acceptDebt`, params);
        return ['success'];
      } catch({response : { data: { message }, data}}) {
        if(typeof(message) !== 'undefined') {
          return [message];
        } else {
          return [JSON.stringify(data)];
        }
      }
    },
    async infor(getParams) {
      const { data } = await server.get(`${API_URI}/orders/infor`,{
        params: {
          ...getParams,
        },
      });
      this.setDataInfor(data);
    },
    async getReceiptByOrderId(params) {
      const { data } = await server.get(`${API_URI}/receipts/getByOrderId/${params.order_id}`);
      this.setDataReceipt(data);
    },
    async completeOrder(params) {
      try {
        const { data } = await server.post(`${API_URI}/orders/completeOrder`, params);
        this.updateStatus(true);
        return ['success'];
      } catch({response : { data: { message }, data}}) {
        if(typeof(message) !== 'undefined') {
          return [message];
        } else {
          return [JSON.stringify(data)];
        }
      }
    },
    async getCardTrelloCreateToday(queries) {
      try {
        const { data } = await server.get(`${API_URI}/orders/getCardTrelloCreateToday`, {
          params: {
            ...queries,
          },
        });
        this.setStatusGetCardTrello(true);
        return data;
      } catch({response : { data: { message }, data}}) {
        if(typeof(message) !== 'undefined') {
          return [message];
        } else {
          return [JSON.stringify(data)];
        }
      }
    },
    async checkLockUser(params) {
      const { data } = await server.get(`${API_URI}/orders/checkLockUser`);
      this.setDataLockUser(data);
    },
    async unRequestComplete(params) {
      try {
        const { data } = await server.post(`${API_URI}/orders/unRequestComplete`, params);
        return ['success'];
      } catch({response : { data: { message }, data}}) {
        if(typeof(message) !== 'undefined') {
          return [message];
        } else {
          return [JSON.stringify(data)];
        }
      }
    },
    async getListOrderUpdatePosition() {
      try {
        const { data } = await server.get(`${API_URI}/orders/getListUpdatePosition`);
        this.setDataUpdatePosition(data);
      } catch (error) {}
    },
    async cancelCompleteOrder(params) {
      try {
        const { data } = await server.post(`${API_URI}/orders/cancelCompleteOrder`, params);
        this.updateStatus(true);
        return ['success'];
      } catch({response : { data: { message }, data}}) {
        if(typeof(message) !== 'undefined') {
          return [message];
        } else {
          return [JSON.stringify(data)];
        }
      }
    },
    async lockOrderNotTrelloId() {
      try {
        const { data } = await server.get(`${API_URI}/orders/lockOrderNotTrelloId`);
        return ['success'];
      } catch (error) {}
    },
    async checkDataOrder(params) {
      const { data } = await server.post(`${API_URI}/orders/checkDataOrder`, params);
      this.setDataCheck(data);
    },
    async cancelOrderAfter7Days() {
      try {
        const { data } = await server.get(`${API_URI}/orders/cancelOrderAfter7Days`);
        return data;
      } catch (error) {}
    },
  },
};

store.addModel(_);
