import {useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {Typography, Divider, Row, Col, Space, Avatar} from 'antd';
import { UserOutlined, ArrowRightOutlined, RightCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import 'moment/locale/vi';
import { API_HOST} from 'constants/index';
import APPCONFIG from 'constants/appConfig';
import formatMoney from 'util/FormatMoney';
import rounding from 'util/Rounding';

import './model';

const {Text} = Typography;

const { LOG : { METHOD: { CREATE, UPDATE } }, 
  ORDER: { STATUS_LIST, STATUS_OBJ, STATUS_OBJ2, STATUS_EXPORT_OBJ2 },
  SUPPLIER: { APPROVED_ORDER_STATUS_OBJ }
} = APPCONFIG;

const Log = (props) => {
  const user = JSON.parse(window.localStorage.getItem('user'));
  
  const dispatch = useDispatch();
  const {log: {getList, setData}} = dispatch;
  
  const { items} = useSelector((state) => state.log);
  
  const arrTypeCustomer = [];
  arrTypeCustomer[1] = 'Khách Quen';
  arrTypeCustomer[2] = 'Vãng Lai';
  
  useEffect(() => {
    if(props.tableId && props.tableName) {
      getList({
        table_name: props.tableName,
        table_id: props.tableId
      });
    } else {
      let arr = [];
      
      switch(props.tableName) {
        case 'orders':
        case 'stock_transfers':
          const item = {
            created_at: moment().format('YYYY-MM-DD HH:mm:ss'),
            children: [{
              method: CREATE,
              created_by_name: user.full_name,
              title: "Đang tạo hồ sơ mới..."
            }]
          };
          arr.push(item);
          break;
      }
      
      setData({items: arr});
    }
  }, [props.tableId, props.tableName]);
  
  return (
    <div>
      { items.length > 0 && 
        items.map(item => {
          const num_row = item.children.length;
          
          return (  
            <div className="wrap-divider">
              {/**ORDERS**/}
              { props.tableName === 'orders' &&
                <>
                  <Divider orientation="left" plain>
                    {item.day_of_week == 'Sunday' ? 'Chủ Nhật' : item.day_of_week == 'Monday' ? 'Thứ Hai' : item.day_of_week == 'Tuesday' ? 'Thứ Ba' : item.day_of_week == 'Wednesday' ? 'Thứ Tư' : item.day_of_week == 'Thursday' ? 'Thứ Năm' : item.day_of_week == 'Friday' ? 'Thứ Sáu' : 'Thứ Bảy'}, {moment(item.created_at).format("DD/MM")}
                  </Divider>
                  
                  
                  { typeof(item.children) !== "undefined" && item.children.map(item2 => {
                    const newData = item2.input ? JSON.parse(item2.input) : {};
                    const oldData = item2.old_data ? JSON.parse(item2.old_data) : {};
                    
                    const arrLastName = item2.last_name ? item2.last_name.split(' ') : '';
                    const lastName = item2.last_name ? arrLastName.pop() : '';
                    
                    let method = '';
                    let color = 'white';
                    switch (item2.method) {
                      case 1:
                        method = "Khởi Tạo";
                        color = "#87d068";
                        break;
                      case 2:
                        method = "Sửa";
                        color = "#87d068";
                        break;
                      case 3:
                        method = "Hủy";
                        color = "#f44336";
                        break;
                      case 4:
                        method = "Công Nợ";
                        color = "#2196f3";
                        break;
                      case 5:
                        method = "Trình Duyệt";
                        color = "orange";
                        break;
                      case 6:
                        method = "Duyệt";
                        color = "#87d068";
                        break;
                      case 7:
                        method = "Tạo Phiếu Thu";
                        color = "#2196f3";
                        break;
                      case 8:
                        method = (item2.has_debt == 1 && Number(item2.total_amount - item2.advance_money) > 0 ? "Hoàn Tất - Công Nợ" : "Hoàn Tất");
                        color = "#2196f3";
                        break;
                      case 9:
                        method = "Yêu Cầu Xuất Kho";
                        color = "#f50";
                        break;
                      case 16:
                        method = "Duyệt Nghiệm Thu";
                        color = "#87d068";
                        break;
                      case 17:
                        method = "Trình Duyệt Mở Thẻ";
                        color = "#f44336";
                        break;
                      case 18:
                        method = "Mở Thẻ";
                        color = "#2196f3";
                        break;
                      case 19:
                        method = "Linked To Portal";
                        color = "#fff";
                        break;
                      case 20:
                        method = "Trình Nghiệm Thu";
                        color = "orange";
                        break;
                      case 22:
                        method = "Hủy Trình Nghiệm Thu";
                        color = "#f44336";
                        break;
                      case 23:
                        method = "Trình Công Nợ";
                        color = "orange";
                        break;
                      case 24:
                        method = "Hủy Trình Công Nợ";
                        color = "#f44336";
                        break;
                      case 25:
                        method = "Duyệt Công Nợ";
                        color = "#87d068";
                        break;
                      case 26:
                        method = "Hủy Nghiệm Thu - " + (newData.cancel_complete_reason ? newData.cancel_complete_reason.toUpperCase() : '');
                        color = "#f44336";
                        break;
                      case 27:
                        method = "Trình Tạm Ngưng";
                        color = "orange";
                        break;
                      case 28:
                        method = "Hủy Tạm Ngưng";
                        color = "#f44336";
                        break;
                      case 29:
                        method = "Duyệt Tạm Ngưng";
                        color = "#87d068";
                        break;
                      case 30:
                        method = "Khóa Thẻ Do Chưa Liên Kết Trello Trong 24h";
                        color = "#f44336";
                        break;
                      case 31:
                        method = "Hủy Hoàn Tất - Công Nợ";
                        color = "#f44336";
                        break;
                      case 33:
                        method = "Khóa Thẻ Do Hết Hạn";
                        color = "#f44336";
                        break;
                      case 34:
                        method = "Hủy Báo Giá Sau 7 Ngày";
                        color = "#f44336";
                        break;
                      case 35:
                        method = "Không Duyệt Báo Giá";
                        color = "#f44336";
                        break;
                      case 36:
                        method = "Không Duyệt Công Nợ";
                        color = "#f44336";
                        break;
                      case 37:
                        method = "Không Duyệt Tạm Ngưng";
                        color = "#f44336";
                        break;
                      case 38:
                        method = "Không Duyệt Nghiệm Thu";
                        color = "#f44336";
                        break;
                      case 39:
                        method = "Không Duyệt Phiếu Chi";
                        color = "#f44336";
                        break;
                      case 99:
                        method = "Thay Đổi";
                        color = "#fff";
                        break;
                      case 100:
                        method = "Duyệt Thay Đổi";
                        color = "#87d068";
                        break;
                    }
                    
                    return (
                      <Row className='ml-0 mr-0'>
                        <Col span={24} className={num_row > 1 ? 'gx-mb-4' : ''}>
                          <Space align="start">
                            <Avatar 
                              size="large" 
                              className="gx-size-20 gx-rounded-circle" 
                              src={item2.avatar ? `${API_HOST}/storage/upload/avatar/${item2.avatar}` : ''}
                              style={{
                                color: '#fff',
                                backgroundColor: '#13c2c2',
                              }}
                            >
                              { !item2.avatar ? (lastName ? Array.from(lastName)[0] : '') : '' }
                            </Avatar>
                            
                            <div>
                              <Text>
                                <span className="font12" style={{color: '#a0a0a0'}}>{moment(item2.created_at).format('HH:mm')},</span> <span style={{ color: color }}>{method}</span> - <span style={{ color: 'orange' }}>{typeof(newData.total_amount) !== "undefined" ? formatMoney(rounding(newData.total_amount)) + ' Đ' : ((item2.method == 99 || item2.method == 100) && newData.type_customer != 0 && newData.type_customer_old != 0 ? (arrTypeCustomer[newData.type_customer_old] + ' -> ' +  arrTypeCustomer[newData.type_customer]) : 0 + ' Đ')}</span>
                              </Text>
                            </div>
                          </Space>

                          {item2.note?
                          <div style={{padding: '0 0 0 30px'}}>
                            <div style={{background: '#525e69', padding: '7px 9px', borderRadius: '0 15px 15px 15px'}}>
                            {item2.note}
                            </div>
                          </div>
                          :''}
                        </Col>
                      </Row>
                    );
                  })}
                </>
              }
              
              {/**RECEIPTS**/}
              { props.tableName === 'receipts' &&
                <>
                  <Divider orientation="left" plain>
                    {item.day_of_week == 'Sunday' ? 'Chủ Nhật' : item.day_of_week == 'Monday' ? 'Thứ Hai' : item.day_of_week == 'Tuesday' ? 'Thứ Ba' : item.day_of_week == 'Wednesday' ? 'Thứ Tư' : item.day_of_week == 'Thursday' ? 'Thứ Năm' : item.day_of_week == 'Friday' ? 'Thứ Sáu' : 'Thứ Bảy'}, {moment(item.created_at).format("DD/MM")}
                  </Divider>
                  
                  
                  { typeof(item.children) !== "undefined" && item.children.map(item2 => {
                    const newData = item2.input ? JSON.parse(item2.input) : {};
                    const oldData = item2.old_data ? JSON.parse(item2.old_data) : {};
                    
                    const arrLastName = item2.last_name ? item2.last_name.split(' ') : '';
                    const lastName = item2.last_name ? arrLastName.pop() : '';
                    
                    let method = '';
                    let color = 'white';
                    switch (item2.method) {
                      case 1:
                        method = "Khởi Tạo" + (newData.type == 1 ? ' Thu' : ' Chi') + (newData.bill_type == 1 ? (newData.type == 1 ? ' Bán Hàng' : ' Mua Hàng') : ' Thường Xuyên');
                        color = "#87d068";
                        break;
                      case 2:
                        method = "Sửa" + (newData.type == 1 ? ' Thu' : ' Chi') + (newData.bill_type == 1 ? (newData.type == 1 ? ' Bán Hàng' : ' Mua Hàng') : ' Thường Xuyên');
                        color = "#87d068";
                        break;
                      case 3:
                        method = "Hủy";
                        color = "#f44336";
                        break;
                      case 5:
                        method = "Trình Duyệt";
                        color = "orange";
                        break;
                      case 6:
                        method = "Duyệt";
                        color = "#87d068";
                        break;
                      case 14:
                        method = "Đã" + (newData.type == 1 ? ' Thu' : ' Chi');
                        color = "#87d068";
                        break;
                      case 15:
                        method = "Tạo Phiếu Nợ Lương";
                        color = "#87d068";
                        break;
                      case 21:
                        method = "Hủy Trình Duyệt";
                        color = "#f44336";
                        break;
                      case 39:
                        method = "Không Duyệt Phiếu Chi";
                        color = "#f44336";
                        break;
                    }
                    
                    return (
                      <Row className='ml-0 mr-0'>
                        <Col span={24} className={num_row > 1 ? 'gx-mb-4' : ''}>
                          <Space align="start">
                            <Avatar 
                              size="large" 
                              className="gx-size-20 gx-rounded-circle" 
                              src={item2.avatar ? `${API_HOST}/storage/upload/avatar/${item2.avatar}` : ''}
                              style={{
                                color: '#fff',
                                backgroundColor: '#13c2c2',
                              }}
                            >
                              { !item2.avatar ? (lastName ? Array.from(lastName)[0] : '') : '' }
                            </Avatar>
                            
                            <div>
                              <div><Text><span className="font12" style={{color: '#a0a0a0'}}>{moment(item2.created_at).format('HH:mm')},</span> <span style={{ color: color }}>{method}</span> - <span style={{ color: 'orange' }}>{typeof(newData.total_amount||newData.salary_advance) !== "undefined" ? formatMoney(rounding(newData.total_amount||newData.salary_advance)) : 0} Đ</span></Text></div>
                              {/*<div><Text>{method} - <span style={{ color: 'orange' }}>{typeof(newData.total_amount) !== "undefined" ? formatMoney(rounding(newData.total_amount)) : 0} Đ</span></Text></div>*/}
                            </div>
                          </Space>

                          {item2.note?
                          <div style={{padding: '0 0 0 30px'}}>
                            <div style={{background: '#525e69', padding: '7px 9px', borderRadius: '0 15px 15px 15px'}}>
                            {item2.note}
                            </div>
                          </div>
                          :''}
                        </Col>
                      </Row>
                    );
                  })}
                </>
              }
              
              {/**STOCK_TRANSFERS**/}
              { props.tableName === 'stock_transfers' &&
                <>
                  <Divider orientation="left" plain>
                    {item.day_of_week == 'Sunday' ? 'Chủ Nhật' : item.day_of_week == 'Monday' ? 'Thứ Hai' : item.day_of_week == 'Tuesday' ? 'Thứ Ba' : item.day_of_week == 'Wednesday' ? 'Thứ Tư' : item.day_of_week == 'Thursday' ? 'Thứ Năm' : item.day_of_week == 'Friday' ? 'Thứ Sáu' : 'Thứ Bảy'}, {moment(item.created_at).format("DD/MM")}
                  </Divider>
                  
                  
                  { typeof(item.children) !== "undefined" && item.children.map(item2 => {
                    const newData = item2.input ? JSON.parse(item2.input) : {};
                    const oldData = item2.old_data ? JSON.parse(item2.old_data) : {};
                    
                    const arrLastName = item2.last_name ? item2.last_name.split(' ') : '';
                    const lastName = item2.last_name ? arrLastName.pop() : '';
                    
                    let method = '';
                    let color = 'white';
                    switch (item2.method) {
                      case 1:
                        method = item2.location==1?"Yêu Cầu Xuất Kho":"Khởi Tạo";
                        color = "#87d068";
                        break;
                      case 2:
                        method = "Sửa";
                        color = "#87d068";
                        break;
                      case 3:
                        method = "Hủy";
                        color = "#f44336";
                        break;
                      case 4:
                        method = "Công Nợ";
                        color = "#2196f3";
                        break;
                      case 5:
                        method = "Trình Duyệt";
                        color = "orange";
                        break;
                      case 6:
                        method = "Duyệt";
                        color = "#87d068";
                        break;
                      case 8:
                        method = "Đóng Thẻ";
                        color = "#2196f3";
                        break;
                      case 10:
                        method = "Tạo Phiếu Chi";
                        color = "#87d068";
                        break;
                      case 11:
                        method = "Nhập Một Phần";
                        color = "#87d068";
                        break;
                      case 12:
                        method = "Nhập Đủ";
                        color = "#2196f3";
                        break;
                      case 13:
                        method = "Trả Hàng";
                        color = "#f44336";
                        break;
                    }
                    
                    return (
                      <Row className='ml-0 mr-0'>
                        <Col span={24} className={num_row > 1 ? 'gx-mb-4' : ''}>
                          <Space align="start">
                            <Avatar 
                              size="large" 
                              className="gx-size-20 gx-rounded-circle" 
                              src={item2.avatar ? `${API_HOST}/storage/upload/avatar/${item2.avatar}` : ''}
                              style={{
                                color: '#fff',
                                backgroundColor: '#13c2c2',
                              }}
                            >
                              { !item2.avatar ? (lastName ? Array.from(lastName)[0] : '') : '' }
                            </Avatar>
                            
                            <div>
                              <div><Text><span className="font12" style={{color: '#a0a0a0'}}>{moment(item2.created_at).format('HH:mm')},</span> <span style={{ color: color }}>{method}</span> - <span style={{ color: 'orange' }}>{typeof(newData.total_amount||newData.total_money) !== "undefined" ? formatMoney(rounding(newData.total_amount||newData.total_money)) : 0} Đ</span></Text></div>
                              {/*<div><Text>{method} - <span style={{ color: 'orange' }}>{typeof(newData.total_amount) !== "undefined" ? formatMoney(rounding(newData.total_amount)) : 0} Đ</span></Text></div>*/}
                            </div>
                          </Space>

                          {item2.note?
                          <div style={{padding: '0 0 0 30px'}}>
                            <div style={{background: '#525e69', padding: '7px 9px', borderRadius: '0 15px 15px 15px'}}>
                            {item2.note}
                            </div>
                          </div>
                          :''}
                        </Col>
                      </Row>
                    );
                  })}
                </>
              }

              {/** KPI **/}
              { props.tableName === 'kpis' &&
                <>
                  { typeof(item.children) !== "undefined" && item.children.map(item2 => {
                    const newData = item2.input ? JSON.parse(item2.input) : {};
                    const oldData = item2.old_data ? JSON.parse(item2.old_data) : {};
                    
                    const arrLastName = item2.last_name ? item2.last_name.split(' ') : '';
                    const lastName = item2.last_name ? arrLastName.pop() : '';
                    
                    let method = '';
                    let color = 'white';
                    switch (item2.method) {
                      case 1:
                        method = "Khởi Tạo";
                        color = "#87d068";
                        break;
                      case 2:
                        method = "Sửa";
                        color = "#87d068";
                        break;
                      case 3:
                        method = "Hủy";
                        color = "#f44336";
                        break;
                      case 5:
                        method = "Trình Duyệt";
                        color = "orange";
                        break;
                      case 6:
                        method = "Duyệt";
                        color = "#87d068";
                        break;
                      case 40:
                        method = "Không Duyệt";
                        color = "#f44336";
                        break;
                    }
                    
                    return (
                      <Row className='ml-0 mr-0'>
                        <Col span={24} style={{ marginBottom: num_row > 1 ? '10px' : '0px'}}>
                          <Space align="start">
                            <Avatar 
                              size="large" 
                              className="gx-size-20 gx-rounded-circle" 
                              src={item2.avatar ? `${API_HOST}/storage/upload/avatar/${item2.avatar}` : ''}
                              style={{
                                color: '#fff',
                                backgroundColor: '#13c2c2',
                                marginLeft: '1px'
                              }}
                            >
                              { !item2.avatar ? (lastName ? Array.from(lastName)[0] : '') : '' }
                            </Avatar>
                            
                            <div>
                              <div><Text><span className="font12" style={{color: '#a0a0a0'}}>{moment(item2.created_at).format('HH:mm')},</span> <span style={{ color: color }}>{method}</span> - <span style={{ color: 'orange' }}>{typeof(newData.total_money) !== "undefined" ? formatMoney(newData.total_money) : 0} VNĐ</span></Text></div>
                              {/*<div><Text>{method} - <span style={{ color: 'orange', fontWeight: 'bold' }}>{typeof(newData.total_amount) !== "undefined" ? formatMoney(rounding(newData.total_amount)) : 0} VND</span></Text></div>*/}
                            </div>
                          </Space>

                          {item2.note?
                          <div style={{padding: '0 0 0 30px'}}>
                            <div style={{background: '#525e69', padding: '7px 9px', borderRadius: '0 15px 15px 15px'}}>
                            {item2.note}
                            </div>
                          </div>
                          :''}
                        </Col>
                      </Row>
                    );
                  })}
                </>
              }
              
              {/** Product Generals **/}
              { props.tableName === 'product_generals' &&
                <>
                  <Divider orientation="left" plain>
                    {item.day_of_week == 'Sunday' ? 'Chủ Nhật' : item.day_of_week == 'Monday' ? 'Thứ Hai' : item.day_of_week == 'Tuesday' ? 'Thứ Ba' : item.day_of_week == 'Wednesday' ? 'Thứ Tư' : item.day_of_week == 'Thursday' ? 'Thứ Năm' : item.day_of_week == 'Friday' ? 'Thứ Sáu' : 'Thứ Bảy'}, {moment(item.created_at).format("DD/MM")}
                  </Divider>
                  
                  
                  { typeof(item.children) !== "undefined" && item.children.map(item2 => {
                    const newData = item2.input ? JSON.parse(item2.input) : {};
                    const oldData = item2.old_data ? JSON.parse(item2.old_data) : {};
                    
                    const arrLastName = item2.created_by_name ? item2.created_by_name.split(' ') : '';
                    const lastName = item2.created_by_name ? arrLastName.pop() : '';
                    
                    let method = '';
                    let color = 'white';
                    switch (item2.method) {
                      case 1:
                        method = "Khởi Tạo";
                        color = "#87d068";
                        break;
                      case 2:
                        method = "Sửa";
                        color = "#87d068";
                        break;
                      case 3:
                        method = "Hủy";
                        color = "#f44336";
                        break;
                      case 5:
                        method = "Trình Duyệt";
                        color = "orange";
                        break;
                      case 6:
                        method = "Duyệt";
                        color = "#87d068";
                        break;
                      case 40:
                        method = "Không Duyệt";
                        color = "#f44336";
                        break;
                    }
                    
                    return (
                      <Row className='ml-0 mr-0'>
                        <Col span={24} className={num_row > 1 ? 'gx-mb-4' : ''}>
                          <Space align="start">
                            <Avatar 
                              size="large" 
                              className="gx-size-20 gx-rounded-circle" 
                              src={''}
                              style={{
                                color: '#fff',
                                backgroundColor: '#13c2c2',
                              }}
                            >
                              { lastName ? Array.from(lastName)[0] : '' }
                            </Avatar>
                            
                            <div>
                              <Text>
                                <span className="font12" style={{color: '#a0a0a0'}}>{moment(item2.created_at).format('HH:mm')},</span> <span style={{ color: color }}>{method}</span>
                              </Text>
                            </div>
                          </Space>

                          {item2.note?
                          <div style={{padding: '0 0 0 30px'}}>
                            <div style={{background: '#525e69', padding: '7px 9px', borderRadius: '0 15px 15px 15px'}}>
                            {item2.note}
                            </div>
                          </div>
                          :''}
                        </Col>
                      </Row>
                    );
                  })}
                </>
              }
              
              {/** Customer Generals **/}
              { props.tableName === 'customer_generals' &&
                <>
                  <Divider orientation="left" plain>
                    {item.day_of_week == 'Sunday' ? 'Chủ Nhật' : item.day_of_week == 'Monday' ? 'Thứ Hai' : item.day_of_week == 'Tuesday' ? 'Thứ Ba' : item.day_of_week == 'Wednesday' ? 'Thứ Tư' : item.day_of_week == 'Thursday' ? 'Thứ Năm' : item.day_of_week == 'Friday' ? 'Thứ Sáu' : 'Thứ Bảy'}, {moment(item.created_at).format("DD/MM")}
                  </Divider>
                  
                  
                  { typeof(item.children) !== "undefined" && item.children.map(item2 => {
                    const newData = item2.input ? JSON.parse(item2.input) : {};
                    const oldData = item2.old_data ? JSON.parse(item2.old_data) : {};
                    
                    const arrLastName = item2.created_by_name ? item2.created_by_name.split(' ') : '';
                    const lastName = item2.created_by_name ? arrLastName.pop() : '';
                    
                    let method = '';
                    let color = 'white';
                    switch (item2.method) {
                      case 1:
                        method = "Khởi Tạo";
                        color = "#87d068";
                        break;
                      case 2:
                        method = "Sửa";
                        color = "#87d068";
                        break;
                      case 3:
                        method = "Hủy";
                        color = "#f44336";
                        break;
                      case 5:
                        method = "Trình Duyệt";
                        color = "orange";
                        break;
                      case 6:
                        method = "Duyệt";
                        color = "#87d068";
                        break;
                      case 40:
                        method = "Không Duyệt";
                        color = "#f44336";
                        break;
                    }
                    
                    return (
                      <Row className='ml-0 mr-0'>
                        <Col span={24} className={num_row > 1 ? 'gx-mb-4' : ''}>
                          <Space align="start">
                            <Avatar 
                              size="large" 
                              className="gx-size-20 gx-rounded-circle" 
                              src={''}
                              style={{
                                color: '#fff',
                                backgroundColor: '#13c2c2',
                              }}
                            >
                              { lastName ? Array.from(lastName)[0] : '' }
                            </Avatar>
                            
                            <div>
                              <Text>
                                <span className="font12" style={{color: '#a0a0a0'}}>{moment(item2.created_at).format('HH:mm')},</span> <span style={{ color: color }}>{method}</span>
                              </Text>
                            </div>
                          </Space>

                          {item2.note?
                          <div style={{padding: '0 0 0 30px'}}>
                            <div style={{background: '#525e69', padding: '7px 9px', borderRadius: '0 15px 15px 15px'}}>
                            {item2.note}
                            </div>
                          </div>
                          :''}
                        </Col>
                      </Row>
                    );
                  })}
                </>
              }
            </div>
          );
        })
      }
    </div>
  );
};

export default Log;