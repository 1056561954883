import React from "react";
import {Route, Switch} from "react-router-dom";

import asyncComponent from "util/asyncComponent";

import './styles.css';

const _ = ({match}) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}dashboard`} component={asyncComponent(() => import('./Dashboard'))}/>
      
      <Route path={`${match.url}sample`} component={asyncComponent(() => import('./SamplePage'))}/>
      <Route path={`${match.url}user`} component={asyncComponent(() => import('./User'))}/>
      <Route path={`${match.url}units`} component={asyncComponent(() => import('./Units'))}/>
      <Route path={`${match.url}product-groups`} component={asyncComponent(() => import('./ProductGroups'))}/>
      <Route path={`${match.url}warehouses`} component={asyncComponent(() => import('./Warehouses'))}/>
      <Route path={`${match.url}products`} component={asyncComponent(() => import('./Products'))}/>
      <Route path={`${match.url}product-natures`} component={asyncComponent(() => import('./ProductNatures'))}/>

      <Route path={`${match.url}warehouse-dashboard`} component={asyncComponent(() => import('./WarehouseDashboard'))}/>
      {/*<Route path={`${match.url}suppliers`} component={asyncComponent(() => import('./Suppliers'))}/>*/}
      <Route path={`${match.url}companies`} component={asyncComponent(() => import('./Company'))}/>
      <Route path={`${match.url}manufacturers`} component={asyncComponent(() => import('./Manufacturers'))}/>
      <Route path={`${match.url}countries`} component={asyncComponent(() => import('./Countries'))}/>
      <Route path={`${match.url}supplier-transactions`} component={asyncComponent(() => import('./SupplierTransactions'))}/>
      <Route path={`${match.url}product-stock`} component={asyncComponent(() => import('./ProductStock'))}/>
      <Route path={`${match.url}internal-transfer`} component={asyncComponent(() => import('./InternalTransfer'))}/>
      <Route path={`${match.url}approve-order-suppliers`} component={asyncComponent(() => import('./SupplierTransactions/LisApprove'))}/>
      <Route path={`${match.url}stock-daily`} component={asyncComponent(() => import('./StockDaily'))}/>
      <Route path={`${match.url}inventory`} component={asyncComponent(() => import('./Inventory'))}/>
      <Route path={`${match.url}customer-transactions`} component={asyncComponent(() => import('./CustomerTransactions'))}/>
      <Route path={`${match.url}delivery`} component={asyncComponent(() => import('./CustomerTransactions/Delivery'))}/>
      <Route path={`${match.url}return-suppliers`} component={asyncComponent(() => import('./ReturnSuppliers'))}/>
      <Route path={`${match.url}customer-returns`} component={asyncComponent(() => import('./CustomerReturns'))}/>

      <Route path={`${match.url}suppliers`} component={asyncComponent(() => import('./Customer'))}/>
	    <Route path={`${match.url}customers`} component={asyncComponent(() => import('./Customer'))}/>
      <Route path={`${match.url}employees`} component={asyncComponent(() => import('./Customer/Employee/list'))}/>
      <Route path={`${match.url}category-group`} component={asyncComponent(() => import('./CategoryGroups'))}/>
      <Route path={`${match.url}car-type`} component={asyncComponent(() => import('./CarTypes'))}/>
	    {/* <Route path={`${match.url}projects`} component={asyncComponent(() => import('./Project'))}/> */}
	    <Route path={`${match.url}receipts`} component={asyncComponent(() => import('./Receipt'))}/>
	    <Route path={`${match.url}orders`} component={asyncComponent(() => import('./Order'))}/>
      <Route path={`${match.url}quotes`} component={asyncComponent(() => import('./Quote'))}/>
      <Route path={`${match.url}create-quotes`} component={asyncComponent(() => import('./Quote/Create/Quote'))}/>
      <Route path={`${match.url}create-orders`} component={asyncComponent(() => import('./Quote/Create/Order'))}/>
      <Route path={`${match.url}sell-dashboard`} component={asyncComponent(() => import('./SellDashboard'))}/>
      <Route path={`${match.url}national-administrative-directory`} component={asyncComponent(() => import('./NationalAdministrativeDirectory'))}/>
      <Route path={`${match.url}notes`} component={asyncComponent(() => import('./Notes'))}/>
      <Route path={`${match.url}banks`} component={asyncComponent(() => import('./Banks'))}/>
      <Route path={`${match.url}trucks`} component={asyncComponent(() => import('./Trucks'))}/>
      <Route path={`${match.url}departments`} component={asyncComponent(() => import('./Departments'))}/>
      <Route path={`${match.url}staff`} component={asyncComponent(() => import('./Staffs'))}/>
      <Route path={`${match.url}salary`} component={asyncComponent(() => import('./Salary'))}/>
      <Route path={`${match.url}bonus-allowances`} component={asyncComponent(() => import('./Salary/BonusAndAllowances'))}/>
      <Route path={`${match.url}work-month-date`} component={asyncComponent(() => import('./WorkMonthDate/List'))}/>
      <Route path={`${match.url}salary-config`} component={asyncComponent(() => import('./Salary/Config'))}/>
      <Route path={`${match.url}kpi`} component={asyncComponent(() => import('./KPI'))}/>

      <Route path={`${match.url}cashier`} component={asyncComponent(() => import('./Cashier'))}/>
      <Route path={`${match.url}credit`} component={asyncComponent(() => import('./Cashier'))}/>
      <Route path={`${match.url}debit`} component={asyncComponent(() => import('./Cashier'))}/>
      <Route path={`${match.url}credittotal`} component={asyncComponent(() => import('./Cashier/CreditTotal'))}/>
      {/*<Route path={`${match.url}report`} component={asyncComponent(() => import('./Report'))}/>
      <Route path={`${match.url}debt-customer`} component={asyncComponent(() => import('./DebtCustomer'))}/>
      <Route path={`${match.url}vat-invoice`} component={asyncComponent(() => import('./VATInvoice'))}/>*/}
      <Route path={`${match.url}accounts`} component={asyncComponent(() => import('./Account'))}/>
      {/*<Route path={`${match.url}tax-report`} component={asyncComponent(() => import('./TaxReport'))}/>*/}
      <Route path={`${match.url}component`} component={asyncComponent(() => import('./Component'))}/>
      <Route path={`${match.url}manage-invoice`} component={asyncComponent(() => import('./ManageInvoice'))}/>
      <Route path={`${match.url}invoice-by-day`} component={asyncComponent(() => import('./InvoiceByDay'))}/>
      <Route path={`${match.url}list-daily`} component={asyncComponent(() => import('./Cashier/ListDaily'))}/>
      <Route path={`${match.url}list-cash-daily`} component={asyncComponent(() => import('./Cashier/ListDaily/CashDaily'))}/>
      <Route path={`${match.url}invoice-transfer`} component={asyncComponent(() => import('./InvoiceTransfer'))}/>
      
      <Route path={`${match.url}category-works`} component={asyncComponent(() => import('./CategoryWorks'))}/>
      <Route path={`${match.url}group-works`} component={asyncComponent(() => import('./GroupWorks'))}/>
      <Route path={`${match.url}assign-works`} component={asyncComponent(() => import('./AssignWork/Statistic'))}/>
      {/*<Route path={`${match.url}statistics`} component={asyncComponent(() => import('./AssignWork/Statistic'))}/>*/}
      
      <Route path={`${match.url}employee`} component={asyncComponent(() => import('./Employee'))}/>
      <Route path={`${match.url}timekeeping`} component={asyncComponent(() => import('./Employee/Timekeeping'))}/>
      <Route path={`${match.url}change-pass`} component={asyncComponent(() => import('./User/ChangePassword'))}/>
      <Route path={`${match.url}infor-staff`} component={asyncComponent(() => import('./User/Detail'))}/>
      <Route path={`${match.url}leave`} component={asyncComponent(() => import('./Leave'))}/>
      <Route path={`${match.url}leave-list`} component={asyncComponent(() => import('./Leave/LeaveList'))}/>
      <Route path={`${match.url}overtime`} component={asyncComponent(() => import('./Overtime'))}/>
      <Route path={`${match.url}approve-overtime`} component={asyncComponent(() => import('./Overtime/List'))}/>
      <Route path={`${match.url}ot-list`} component={asyncComponent(() => import('./Overtime/OTList'))}/>
      <Route path={`${match.url}calculate-ot`} component={asyncComponent(() => import('./Overtime/CalculateOT'))}/>
      
      <Route path={`${match.url}report-quotes`} component={asyncComponent(() => import('./Report/Excel'))}/>
      <Route path={`${match.url}report-returns`} component={asyncComponent(() => import('./Report/Returns'))}/>
      <Route path={`${match.url}report-sales`} component={asyncComponent(() => import('./Report/Sales'))}/>
      <Route path={`${match.url}lock-order`} component={asyncComponent(() => import('./Quote/LockOrder'))}/>

      <Route path={`${match.url}trello`} component={asyncComponent(() => import('./Trello'))}/>
      <Route path={`${match.url}cards`} component={asyncComponent(() => import('./Trello/Cards'))}/>

      <Route path={`${match.url}complete-card`} component={asyncComponent(() => import('./Quote/Complete'))}/>
      <Route path={`${match.url}alert-card`} component={asyncComponent(() => import('./Quote/Alert'))}/>

      <Route path={`${match.url}demo`} component={asyncComponent(() => import('./Component/ToDo'))}/>
      <Route path={`${match.url}manager`} component={asyncComponent(() => import('./Component/ToDo/Manager'))}/>
      
      <Route path={`${match.url}signout`} component={asyncComponent(() => import('./User/SignOut'))}/>
      <Route path={`${match.url}permissions`} component={asyncComponent(() => import('./Permissions'))}/>
      
      <Route path={`${match.url}product-config`} component={asyncComponent(() => import('./Products/Config'))}/>
      <Route path={`${match.url}product-system`} component={asyncComponent(() => import('./Products/ProductGeneral'))}/>
      
      <Route path={`${match.url}convert-customer`} component={asyncComponent(() => import('./Convert/Customer'))}/>

      <Route path={`*`} component={asyncComponent(() => import('./PageNotFound'))}/>
    </Switch>
  </div>
);

export default _;